@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?o8id8f');
  src:  url('fonts/icomoon.eot?o8id8f#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?o8id8f') format('truetype'),
    url('fonts/icomoon.woff?o8id8f') format('woff'),
    url('fonts/icomoon.svg?o8id8f#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-minus:before {
  content: "\e91e";
}
.icon-arrow-left:before {
  content: "\e907";
}
.icon-home:before {
  content: "\e900";
}
.icon-info-circle-fill:before {
  content: "\e901";
}
.icon-info-circle-stroke:before {
  content: "\e902";
}
.icon-log-out:before {
  content: "\e903";
}
.icon-notifications-stroke:before {
  content: "\e904";
}
.icon-plus:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e91d";
}
.icon-question-circle-stroke:before {
  content: "\e906";
}
.icon-settings-stroke:before {
  content: "\e908";
}
.icon-sort:before {
  content: "\e909";
}
.icon-success-circle-fill:before {
  content: "\e90a";
}
.icon-user:before {
  content: "\e90b";
}
.icon-users:before {
  content: "\e90c";
}
.icon-warning-triangle-fill:before {
  content: "\e90d";
}
.icon-apps:before {
  content: "\e90e";
}
.icon-bin-stroke:before {
  content: "\e90f";
}
.icon-building:before {
  content: "\e910";
}
.icon-check:before {
  content: "\e911";
}
.icon-chevron-down:before {
  content: "\e912";
}
.icon-chevron-left:before {
  content: "\e913";
}
.icon-chevron-right:before {
  content: "\e914";
}
.icon-chevron-up:before {
  content: "\e915";
}
.icon-close:before {
  content: "\e916";
}
.icon-double-chevron-left:before {
  content: "\e917";
}
.icon-double-chevron-right:before {
  content: "\e918";
}
.icon-edit-stroke:before {
  content: "\e919";
}
.icon-error-circle-fill:before {
  content: "\e91a";
}
.icon-export:before {
  content: "\e91b";
}
.icon-filter-stroke:before {
  content: "\e91c";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-copy:before {
  content: "\e92c";
}
.icon-download2:before {
  content: "\e9c5";
}
.icon-attachment:before {
  content: "\e9cd";
}
