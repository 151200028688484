@font-face {
  font-family: 'Gotham';
  src: url('assets/font/Gotham-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('assets/font/Gotham-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('assets/font/Gotham-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('assets/font/Gotham-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('assets/font/Gotham-Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('assets/font/Gotham-BookItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('assets/font/Gotham-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('assets/font/Gotham-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('assets/font/Gotham-Thin.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('assets/font/Gotham-ThinItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

:root {
  --blue: #009fe3;
  --deep-blue: #000094;
  --light-blue: #0053FF;
  --extra-light-blue: #e5f5fc;
  --green: #9ECC2E;
  --light-green: #def7e4;
  --yellow: #fab621;
  --light-yellow: #fdf4de;
  --red: #e83e49;
  --light-red: #fdebed;
  --black: #121212;
  --soft-dark: #333333;
  --heavy-grey: #7b7b7b;
  --grey: #bbbbbb;
  --light-grey: #e6e6e6;
  --extra-light-grey: #f5f5f5;
  --white: #ffffff;
  --font-small: 0.675rem;
  --font-large: 1.25rem;
  --box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.05);
}

/* ======= FONTS ======= */
body {
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: normal;
  //font-size: 16px;
  font-size: 12px;
  background-color: var(--white);
  color: var(--soft-dark);
}

/*  ================= HEADERS =================  */
h1 {
  font-size: 20px;
  //font-size: 30px;
  font-weight: 700;
  color: var(--soft-dark);

  &.blue-title {
    color: var(--blue);
    margin: 0;
  }
}

h1.blue-title::after {
  content: '';
  display: block;
  margin: 0.25rem 0 2rem 0;
  border: 2px solid var(--blue);
  border-radius: 5px;
  width: 73px;
}

h3.long-blue-title::after {
  content: '';
  display: block;
  margin: 0.25rem 0 0 0;
  border: 2px solid var(--blue);
  border-radius: 5px;
  width: 125px;
}

h3.really-long-blue-title::after {
  content: '';
  display: block;
  margin: 0.25rem 0 0 0;
  border: 2px solid var(--blue);
  border-radius: 5px;
  width: 260px;
}

h2 {
  font-size: 22px;
  font-weight: 500;
  color: var(--soft-dark);

  &.underlined {
    margin-bottom: 0;

    &::after {
      content: '';
      display: block;
      margin: 10px 0 1.5rem 0;
      border-top: 2px solid var(--extra-light-grey);
      width: 100%;
    }
  }
}

paragraph {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5rem;
}

small {
  font-size: 18px;
  font-weight: 400;
}

hr {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: solid thin var(--light-grey);
}

/*  ================= LINKS =================  */

a:link,
a:active,
a:visited,
a:hover {
  text-decoration: none;
  color: var(--heavy-grey);
}

/*  ================= ACTION BUTTONS (above tables) =================  */

.action-buttons {
  margin-bottom: 1rem;

  .mdc-button.mat-mdc-button {
    font-size: 0.8rem;
    position: relative;

    &:not(:first-child) {
      margin-left: 1rem;
    }

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      top: 25%;
      height: 50%;
      right: -0.6rem;
      border-right: solid thin var(--soft-dark);
    }

    .mdc-button__label {
      text-decoration: underline;
      text-transform: uppercase;
    }
  }
}

/*  ================= USER STATUS =================  */

.user-status-chip {
  margin: auto;
  text-align: center;
  display: block;
  padding: 0.25rem 0.75rem;
  box-sizing: border-box;
  border-radius: 5px;
  color: var(--white);

  &.orange {
    background-color: var(--yellow);
  }

  &.green {
    background-color: var(--green);
  }

  &.dark {
    background-color: var(--heavy-grey);
  }
}

/*  ================= BUTTON =================  */

.link-style {
  /*font-size: initial;*/
  font-family: inherit;
  padding: 0;
  border: none;
  background: none;

  &:hover,
  &:focus {
    color: var(--blue);
    text-decoration: underline;
    cursor: pointer;
  }
}
