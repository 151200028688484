/************ BUTTONS *****************/
.mdc-button.mat-mdc-button {
  border-radius: 5px;
  border: solid thin transparent;
  width: 200px;
  height: 45px;
  //font-size: var(--font-small);
  text-transform: uppercase;
  min-width: 40px;

  &:not(:disabled) {
    color: var(--soft-dark);
  }

  &.small {
    width: 140px;
  }

  &.redirection-button {
    width: 51px;
    height: 49px;
    color: var(--blue);
    background-color: var(--extra-light-blue);
  }

  &.icon {
    width: auto;
  }

  &.large-icon {
    width: auto;
    font-size: 1em;
  }

  .mat-icon {
    overflow: visible;
  }

  &.primary-button {
    background-color: var(--blue);
    color: var(--white);

    &:disabled {
      background-color: var(--light-blue);
    }

    &:not(:disabled):hover {
      background-color: var(--deep-blue);
    }
  }

  &.secondary-button {
    border: 1px solid var(--blue);
    background-color: var(--white);
    color: var(--blue);

    &:disabled {
      background-color: var(--extra-light-grey);
      border-color: transparent;
      color: var(--light-blue);
    }

    &:not(:disabled):hover {
      background-color: var(--blue);
      border-color: transparent;
      color: var(--white);
    }
  }

  &.third-button {
    background-color: var(--white);
    border: 1px solid var(--heavy-grey);
    color: var(--heavy-grey);

    &:disabled {
      background-color: var(--extra-light-grey);
      color: var(--grey);
      border-color: transparent;
    }

    &:not(:disabled):hover {
      background-color: var(--heavy-grey);
      color: var(--white);
      border-color: transparent;
    }
  }

  &.delete-button {
    background-color: var(--white);
    border: 1px solid var(--red);
    color: var(--red);

    &:disabled {
      background-color: var(--extra-light-grey);
      color: var(--grey);
      border-color: transparent;
    }

    &:not(:disabled):hover {
      background-color: var(--red);
      color: var(--white);
      border-color: transparent;
    }
  }

  &.add-button {
    background-color: var(--white);
    border: 1px solid var(--blue);
    color: var(--blue);

    &:disabled {
      background-color: var(--extra-light-grey);
      color: var(--grey);
      border-color: transparent;
    }

    &:not(:disabled):hover {
      background-color: var(--blue);
      color: var(--white);
      border-color: transparent;
    }
  }

  .mdc-button__label {
    letter-spacing: normal;
  }

  .mat-mdc-button-touch-target {
    height: 45px;
  }
}

/******* SELECT ********/

.mat-mdc-form-field-type-mat-select {
  &.small {
    width: 200px;
  }

  .mat-mdc-text-field-wrapper {
    cursor: pointer;
  }

  .mat-mdc-form-field-icon-suffix {
    align-self: start;

    .mat-icon {
      position: relative;
      text-align: center;
      line-height: 24px;
      font-size: 9px;
      padding: 10px;
    }
  }

  .mat-mdc-select:not(.mat-mdc-select-disabled) .mat-mdc-select-value {
    color: var(--soft-dark);
  }

  .mat-mdc-select-arrow {
    display: none;
  }
}

.select-button {
  position: relative;

  .mat-mdc-select {
    position: absolute;
    left: 0;
    top: 0;
    width: 200px;
    height: 100%;
    pointer-events: none;
    visibility: hidden;

    .mat-mdc-select-trigger {
      height: 100%;
      pointer-events: none;
    }
  }
}

/******** FORM FIELD *********/
.mat-mdc-text-field-wrapper.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--white);
  border-top: solid thin var(--light-grey);
  border-right: solid thin var(--light-grey);
  border-left: solid thin var(--light-grey);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--grey);
  font-weight: normal;
}

.mat-mdc-form-field {
  border-radius: 5px;
  width: 350px;

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
    border-bottom-color: var(--blue);
  }
  // &:not(.textarea-style) .mat-mdc-text-field-wrapper {
  //   height: 44px;
  // }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 18px;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 22px;
    letter-spacing: normal;
  }
}

/****** CHECKBOX ***********/

.mat-mdc-option {
  .mat-pseudo-checkbox {
    width: 22px;
    height: 22px;
  }

  .mat-pseudo-checkbox-full {
    border-radius: 5px;
    border-width: 1px;
  }
}

.mat-mdc-checkbox.mat-primary
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: var(--white);
  left: 20%;
  right: 20%;
  top: 20%;
  bottom: 20%;
  width: 60%;
}

.mat-mdc-checkbox.mat-primary
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__mixedmark {
  border-color: var(--white);
  width: 60%;
}

.mat-mdc-checkbox.mat-primary .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  border-radius: 5px;
  border-width: 1px;
  width: 22px;
  height: 22px;
  top: calc((var(--mdc-checkbox-state-layer-size, 40px) - 22px) / 2);
  left: calc((var(--mdc-checkbox-state-layer-size, 40px) - 22px) / 2);
}

/********** TOOLTIP **********/
.mat-mdc-tooltip-panel {
  //font-size: var(--font-small);

  &.mat-mdc-tooltip-panel-above .mdc-tooltip__surface {
    position: relative;
    overflow: visible;
    background: var(--soft-dark);

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      bottom: -7px;
      right: 50%;
      transform: translateX(50%);
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      will-change: transform, opacity;
      border-top: 7px solid var(--soft-dark);
    }
  }

  &.mat-mdc-tooltip-panel-right .mdc-tooltip__surface {
    position: relative;
    overflow: visible;
    background: var(--soft-dark);

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 50%;
      transform: translateY(-50%);
      left: -7px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 7px solid var(--soft-dark);
      will-change: transform, opacity;
    }
  }

  &.mat-mdc-tooltip-panel-below .mdc-tooltip__surface {
    position: relative;
    overflow: visible;
    background: var(--soft-dark);

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: -7px;
      right: 50%;
      transform: translateX(50%);
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      will-change: transform, opacity;
      border-bottom: 7px solid var(--soft-dark);
    }
  }

  &.mat-mdc-tooltip-panel-left .mdc-tooltip__surface {
    position: relative;
    overflow: visible;
    background: var(--soft-dark);

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 50%;
      transform: translateY(-50%);
      right: -7px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid var(--soft-dark);
      will-change: transform, opacity;
    }
  }
}

/********** MENU **********/
.mat-mdc-menu-panel.mat-mdc-menu-panel.mdc-menu-surface--open {
  max-width: none;
  box-shadow: var(--box-shadow);

  button.mat-mdc-menu-item {
    &:hover {
      span {
        text-decoration: underline;
        background: none;
      }

      &:not([disabled]) {
        background: none;
      }
    }

    .mat-icon {
      height: 25px;
      width: 25px;
      font-size: 25px;
      overflow: visible;
      text-align: center;
    }
  }
}

/********** SNACKBAR ************/
.mdc-snackbar.mat-mdc-snack-bar-container {
  margin-top: calc(60px + 1rem);
  margin-right: 1rem;

  .mdc-snackbar__surface {
    box-shadow: none;
  }

  &.success .mdc-snackbar__surface {
    background-color: var(--light-green);
  }

  &.error .mdc-snackbar__surface {
    background-color: var(--light-red);
  }

  &.warning .mdc-snackbar__surface {
    background-color: var(--light-yellow);
  }

  &.info .mdc-snackbar__surface {
    background-color: var(--extra-light-blue);
  }
}

/********** TABS ************/
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  //font-size: 1rem;
  letter-spacing: normal;
}

.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mdc-tab,
.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  padding-left: 1rem;
  padding-right: 1rem;

  &.mdc-tab {
    flex-grow: initial;
  }

  &.mdc-tab--active {
    background-color: var(--extra-light-blue);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
}

.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-width: 4px;
}

.mat-mdc-tab-labels {
  position: relative;
  margin-bottom: 2rem;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    border-bottom: solid 4px var(--light-grey);
  }
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs.stretch > .mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: initial;
}

.mat-mdc-tab-group {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/*********** TABLE ************/
table.mat-mdc-table {
  border: solid thin var(--extra-light-grey);
  border-radius: 5px;
  overflow: hidden;

  .mat-mdc-row.mdc-data-table__row:nth-child(odd) {
    background-color: var(--extra-light-grey);
    //height: min-content;
  }

  .mat-mdc-header-cell.mdc-data-table__header-cell {
    border-bottom: solid thin var(--soft-dark);
    font-size: 14px;
    padding: 0 5px 0 5px;
  }

  .mat-mdc-cell.mdc-data-table__cell {
    border-bottom-color: var(--light-grey);
    font-size: 12px;
    padding: 0 5px 0 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /*  .mat-mdc-header-row.mdc-data-table__header-row {
    height: 3em;
  }*/
}
.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: hidden;
}
