/* ================= GLOBAL ================= */
html,
body {
  font-family: Gotham, sans-serif;
  margin: 0;
  height: 100%;
}

.v-hidden {
  visibility: hidden;
}

.scrollbar {
  height: 300px;
  width: 50%;
  overflow: auto;
  padding: 0 10px;
}

/*        ScrollBar 4    */

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border: 0.2px solid #7e7e7e00;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  scrollbar-width: thin;
  border-radius: 8px;
}

.custom-content-table {
  height: 49vh;
  overflow: scroll;
  width: 100%;
}
